import { Room } from '@ikon-web/space-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../shared/data/store';

const initialState: {
  roomId?: string;
  usersTyping: string[];
  input: string;
  isHistoryLoaded: boolean;
  isBlockingAction: boolean;
  showScrollToBottom: boolean;
} = {
  usersTyping: [],
  input: '',
  isHistoryLoaded: false,
  isBlockingAction: false,
  showScrollToBottom: false,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatRoom: (state, { payload: { room } }: PayloadAction<{ room: Room }>) => {
      if (state.roomId !== room.id) {
        state.usersTyping = [];
        state.isHistoryLoaded = false;
      }
      state.roomId = room.id;
    },
    setChatUserTyping: (state, { payload: { user, active } }: PayloadAction<{ user: string; active: boolean }>) => {
      if (active) {
        state.usersTyping = Array.from(new Set([...state.usersTyping, user]));
      } else {
        state.usersTyping = state.usersTyping.filter((u) => u !== user);
      }
    },
    setChatInput: (state, { payload: { input } }: PayloadAction<{ input: string }>) => {
      state.input = input;
    },
    setBlockingAction: (state, { payload: { active } }: PayloadAction<{ active: boolean }>) => {
      state.isBlockingAction = active;
    },
    setScrollToBottom: (state, { payload }: PayloadAction<boolean>) => {
      state.showScrollToBottom = payload;
    },
    clearChat: (state) => {
      state.isHistoryLoaded = true;
      state.isBlockingAction = false;
    },
    resetChat: () => initialState,
  },
});

export const chatReducer = chatSlice.reducer;

export const { setChatRoom, setChatUserTyping, setChatInput, setBlockingAction, setScrollToBottom, clearChat, resetChat } = chatSlice.actions;

export const selectChatUsersTyping = (state: RootState) => state.chat.usersTyping;
export const selectChatInput = (state: RootState) => state.chat.input;
export const selectIsBlockingAction = (state: RootState) => state.chat.isBlockingAction;
export const selectShowScrollToBottom = (state: RootState) => state.chat.showScrollToBottom;

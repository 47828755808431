import { SpacePublic } from '@ikon-web/space-types';
import { Link, Typography } from '@mui/material';
import { useState } from 'react';
import reactStringReplace from 'react-string-replace';
import { PrivacyPolicyModal } from '../../components/privacy-policy-modal';
import { TermsOfServiceModal } from '../../components/terms-of-service-modal';

export function DisclaimerText({ space }: { space: SpacePublic }) {
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const [termsOfServiceOpen, setTermsOfServiceOpen] = useState(false);

  let disclaimerText: any = space.customisation.disclaimer;
  if (space.customisation.disclaimer) {
    if (space.customisation.privacyPolicyUrl) {
      disclaimerText = reactStringReplace(disclaimerText, '{PRIVACY_POLICY}', (_, i) => (
        <Link key={`pp-${i}`} href={space.customisation.privacyPolicyUrl} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </Link>
      ));
    }
    if (space.customisation.termsAndConditionsUrl) {
      disclaimerText = reactStringReplace(disclaimerText, '{TERMS_AND_CONDITIONS}', (_, i) => (
        <Link key={`tc-${i}`} href={space.customisation.termsAndConditionsUrl} target="_blank" rel="noopener noreferrer">
          Terms and Conditions
        </Link>
      ));
    }
  }

  return (
    <>
      {space.customisation.disclaimer ? (
        <Typography variant="body2" className="ikon-disclaimer-text" sx={{ fontSize: '10px', lineHeight: '14px' }}>
          {disclaimerText}
        </Typography>
      ) : (
        <Typography variant="body2" sx={{ fontSize: '10px', lineHeight: '14px' }}>
          By continuing to interact with AI Agent you agree to our{' '}
          <Link onClick={() => setTermsOfServiceOpen(true)} sx={{ cursor: 'pointer' }}>
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link onClick={() => setPrivacyPolicyOpen(true)} sx={{ cursor: 'pointer' }}>
            Privacy Policy
          </Link>
          .
        </Typography>
      )}

      <TermsOfServiceModal open={termsOfServiceOpen} close={() => setTermsOfServiceOpen(false)} />
      <PrivacyPolicyModal open={privacyPolicyOpen} close={() => setPrivacyPolicyOpen(false)} />
    </>
  );
}

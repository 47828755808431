import { Box } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectChatGroups } from '../../shared/data/container.slice';
import { RootState } from '../../shared/data/store';
import { Disclaimer } from '../disclaimer/disclaimer';
import { ChatRootContainer } from './chat-root-container';
import { setScrollToBottom } from './chat.slice';

export const ChatContainers = forwardRef((props: { alignEnd: boolean; centerMessages?: boolean; hasFade?: boolean; disableInitialScroll?: boolean }, ref) => {
  const dispatch = useDispatch();
  const groups = useSelector(selectChatGroups);
  const chatContainerUpdates = useSelector((state: RootState) => state.containerUpdates.count);
  const chatContainerForceUpdates = useSelector((state: RootState) => state.containerUpdates.forceCount);

  const [keepScrolled, setKeepScrolled] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const previousIsBottom = useRef(true);

  function checkScrollToBottom() {
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    const isBottom = scrollTop + clientHeight >= scrollHeight - 10;
    if (previousIsBottom.current !== isBottom) {
      dispatch(setScrollToBottom(!isBottom));
      previousIsBottom.current = isBottom;
    }
  }

  useImperativeHandle(ref, () => ({
    scrollToBottom: () => {
      dispatch(setScrollToBottom(false));
      setScrollToBottom(true);
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    },
  }));

  useEffect(() => {
    checkScrollToBottom();

    // Handles scrolling from new messages
    // If the user has scrolled up, don't scroll to the bottom
    if (!groups.length) return;

    if (keepScrolled) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [groups, chatContainerUpdates, keepScrolled]);

  useEffect(() => {
    // Handles scrolling from force updates, e.g. when message from the current user is received
    checkScrollToBottom();
    containerRef.current.scrollTop = containerRef.current.scrollHeight;
  }, [containerRef, chatContainerForceUpdates]);

  const handleContainerScroll = () => {
    checkScrollToBottom();

    if (containerRef.current) {
      // Check if the user has scrolled to the bottom
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      const isBottom = scrollTop + clientHeight >= scrollHeight - 10;
      if (keepScrolled !== isBottom) {
        setKeepScrolled(isBottom);
        dispatch(setScrollToBottom(!isBottom));
      }
    }
  };

  const rootContainers = useMemo(() => {
    return (
      <>
        {groups.map((group) => (
          <ChatRootContainer key={group.id} group={group} centerMessages={props.centerMessages} />
        ))}
      </>
    );
  }, [props.centerMessages, groups]);

  return (
    <Box
      ref={containerRef}
      sx={{
        width: '100%',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        scrollbarGutter: 'stable',
        maskImage: props.hasFade ? 'linear-gradient(transparent 0%, black 24px, black calc(100% - 24px), transparent 100%)' : null,
        px: 2,
      }}
      onScroll={handleContainerScroll}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          placeContent: props.alignEnd ? 'flex-end' : 'flex-start',
          gap: 2,
          maxWidth: '800px',
          margin: '0 auto',
          py: props.hasFade ? 3 : 1,
          minHeight: '100%',
        }}
      >
        {rootContainers}
        {groups.length > 0 && <Disclaimer />}
      </Box>
    </Box>
  );
});

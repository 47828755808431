import { Box } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectChatGroups } from '../../shared/data/container.slice';
import { RootState } from '../../shared/data/store';
import { ChatRootContainer } from './chat-root-container';
import { setScrollToBottom } from './chat.slice';
import { useContainersUserTopScroll } from './use-containers-user-top-scroll';

export const ChatContainersScrollLatestTop = forwardRef((props: { alignEnd: boolean; centerMessages?: boolean; hasFade?: boolean; disableInitialScroll?: boolean }, ref) => {
  const dispatch = useDispatch();
  const groups = useSelector(selectChatGroups);
  const chatContainerUpdates = useSelector((state: RootState) => state.containerUpdates.count);
  const chatContainerForceUpdates = useSelector((state: RootState) => state.containerUpdates.forceCount);
  const { oldGroups, newGroups } = useContainersUserTopScroll();

  const containerRef = useRef<HTMLDivElement>(null);
  const latestContainerRef = useRef<HTMLDivElement>(null);
  const latestGroupRef = useRef<string>();
  const userScrolledSinceLatestGroup = useRef(false);
  const systemScrolled = useRef(false);
  const scrollToBottom = useRef(false);
  const previousIsBottom = useRef(true);
  const debounceTimeout = useRef(null);

  useImperativeHandle(ref, () => ({
    scrollToBottom: () => {
      scrollToBottom.current = true;
      dispatch(setScrollToBottom(false));
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    },
  }));

  function setSystemScroll() {
    // Track system scrolls to ignore scroll events they trigger
    systemScrolled.current = true;
    if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(() => {
      systemScrolled.current = false;
    }, 100);
  }

  function scrollBySystem() {
    setSystemScroll();

    containerRef.current.scrollTop = containerRef.current.scrollHeight - latestContainerRef.current.scrollHeight;
  }

  function checkScrollToBottom() {
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    const isBottom = scrollTop + clientHeight >= scrollHeight - 10;
    if (previousIsBottom.current !== isBottom) {
      dispatch(setScrollToBottom(!isBottom));
      previousIsBottom.current = isBottom;
    }
  }

  useEffect(() => {
    setSystemScroll();
    checkScrollToBottom();

    if (scrollToBottom.current && latestGroupRef.current !== groups.at(-1)?.id) {
      scrollBySystem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatContainerUpdates]);

  useEffect(() => {
    if (!groups.length) return;

    if (latestGroupRef.current !== groups.at(-1)?.id) {
      // New group started
      latestGroupRef.current = groups.at(-1)?.id;
      userScrolledSinceLatestGroup.current = false;
      if (scrollToBottom.current) {
        scrollBySystem();
        scrollToBottom.current = false;

        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups, scrollToBottom]);

  useEffect(() => {
    // Handles scrolling from force updates, e.g. when message from the current user is received
    scrollBySystem();
    checkScrollToBottom();
    scrollToBottom.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, chatContainerForceUpdates]);

  const handleContainerScroll = () => {
    checkScrollToBottom();

    if (systemScrolled.current) {
      return;
    }

    if (containerRef.current) {
      // Check if the user has scrolled to the bottom
      userScrolledSinceLatestGroup.current = true;
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      const isBottom = scrollTop + clientHeight >= scrollHeight - 10;
      if (scrollToBottom.current !== isBottom) {
        scrollToBottom.current = isBottom;
      }
    }
  };

  const rootContainers = useMemo(() => {
    return (
      <>
        {oldGroups.map((group) => (
          <ChatRootContainer key={group.id} group={group} centerMessages={props.centerMessages} />
        ))}
      </>
    );
  }, [props.centerMessages, oldGroups]);

  const newRootContainers = useMemo(() => {
    return (
      <>
        {newGroups.map((group) => (
          <ChatRootContainer key={group.id} group={group} centerMessages={props.centerMessages} useStagger={true} />
        ))}
      </>
    );
  }, [props.centerMessages, newGroups]);

  return (
    <Box
      ref={containerRef}
      sx={{
        width: '100%',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        scrollbarGutter: 'stable',
        maskImage: props.hasFade ? 'linear-gradient(transparent 0%, black 24px, black calc(100% - 24px), transparent 100%)' : null,
        px: 2,
      }}
      onScroll={handleContainerScroll}
    >
      <Box
        className="ikon-chat-container-groups"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          placeContent: props.alignEnd ? 'flex-end' : 'flex-start',
          gap: 2,
          maxWidth: '800px',
          margin: '0 auto',
          pt: props.hasFade ? 3 : 1,
          pb: 0,
        }}
      >
        {rootContainers}
      </Box>
      <Box
        ref={latestContainerRef}
        className="ikon-chat-container-groups"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          placeContent: 'flex-start',
          gap: 2,
          maxWidth: '800px',
          margin: '0 auto',
          py: props.hasFade ? 3 : 1,
          minHeight: '100%',
        }}
      >
        {newRootContainers}
      </Box>
    </Box>
  );
});

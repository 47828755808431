import { UiElementProgressBar } from '@ikon-web/ikon-client';
import { Box, LinearProgress } from '@mui/material';

export function UiProgressBar({ element }: { element: UiElementProgressBar }) {
  const className = `ikon-progressbar ${element.styleId}`;

  return (
    <Box className={className} sx={{ width: '100%' }}>
      <LinearProgress variant="determinate" value={element.percentage} sx={{ borderRadius: '2px', '& .MuiLinearProgress-bar': { borderRadius: '2px' } }} />
    </Box>
  );
}

import { UIElementLabels } from '@ikon-web/event-shared';
import { Box, SxProps } from '@mui/material';
import Stack from '@mui/material/Stack';
import { UiContainerElements } from './ui-container-elements';

export function UiContainerRight({ container, isCard, myMessage, disabled }: { container: any; isCard: boolean; myMessage: boolean; disabled?: boolean }) {
  const className = `ikon-container-right ${container.styleId}`;
  const sx: SxProps = {
    alignItems: 'center',
    justifyContent: 'right',
  };

  if (container.labels?.includes(UIElementLabels.Wrap)) {
    sx['flexWrap'] = 'wrap';
  }

  return (
    <Box sx={{ display: 'inline-block', flexGrow: 1 }}>
      <Stack className={className} direction="row" spacing={container.gap || 0} useFlexGap sx={sx}>
        <UiContainerElements container={container} isCard={isCard} myMessage={myMessage} disabled={disabled} />
      </Stack>
    </Box>
  );
}

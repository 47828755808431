import { UIButtonVariant, UIElementLabels } from '@ikon-web/event-shared';
import { UiElementType } from '@ikon-web/ikon-client';
import { Button, IconButton, SxProps } from '@mui/material';
import { MouseEvent, useMemo } from 'react';
import { useIkon } from '../../shared/hooks/use-ikon';
import { UiContainerElements } from './ui-container-elements';

export function UiContainerButton({ container, myMessage, disabled }: { container: any; myMessage: boolean; disabled?: boolean }) {
  const ikon = useIkon();

  const sx: SxProps = {};
  const isDisabled = disabled || container.labels?.includes(UIElementLabels.Disabled);
  const className = `ikon-button ${container.styleId}`;

  function handleClick(event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) {
    event.stopPropagation();
    ikon.sendAction(container.actionId);
  }

  const variant = useMemo(() => {
    switch (container.variant) {
      case UIButtonVariant.Text:
        return 'text';
      case UIButtonVariant.Contained:
        return 'contained';
      default:
        return 'outlined';
    }
  }, [container.variant]);

  const size = useMemo(() => {
    if (container.labels.includes(UIElementLabels.SizeLarge)) return 'large';
    if (container.labels.includes(UIElementLabels.SizeSmall)) return 'small';
    return 'medium';
  }, [container.labels]);

  const buttonElements = container.elements.at(0)?.elements;
  const hasSymbol = Array.isArray(buttonElements) && buttonElements.some((be) => be.type === UiElementType.MaterialSymbol);
  const hasText = Array.isArray(buttonElements) && buttonElements.some((be) => be.type === UiElementType.Text);

  if (Array.isArray(buttonElements) && buttonElements.length === 1 && hasSymbol && variant !== 'contained') {
    return (
      <IconButton className={className} size={size} disabled={isDisabled} onClick={handleClick}>
        <UiContainerElements container={container} myMessage={myMessage} disabled={disabled} />
      </IconButton>
    );
  }

  if (variant === 'contained' && hasSymbol && !hasText) {
    switch (size) {
      case 'small':
        sx['padding'] = '7px';
        break;
      case 'large':
        sx['padding'] = '12px';
        break;
      default:
        sx['padding'] = '8px';
        break;
    }
    sx['margin'] = 0;
    sx['minWidth'] = 'unset';
  }

  return (
    <Button className={className} sx={sx} variant={variant} size={size} disabled={isDisabled} onClick={handleClick}>
      <UiContainerElements container={container} myMessage={myMessage} disabled={disabled} />
    </Button>
  );
}

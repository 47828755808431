import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { UiContainer } from '../../components/ikon/ui-container';
import { selectCollapsedContainerIds } from '../../shared/data/container.slice';

export function Collapsed() {
  const rootContainerIds = useSelector(selectCollapsedContainerIds);

  if (!rootContainerIds?.length) return null;

  return (
    <Box
      sx={{
        width: '100%',
        pt: 3,
        px: 3,
        m: 0,
        overflowX: 'hidden',
      }}
    >
      {rootContainerIds.map((rootContainerId) => (
        <UiContainer key={rootContainerId} containerId={rootContainerId} myMessage={false} />
      ))}
    </Box>
  );
}

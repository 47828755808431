import { UIElementLabels } from '@ikon-web/event-shared';
import { UiElementIcon } from '@ikon-web/ikon-client';
import { Box } from '@mui/material';
import { UiColorToString } from '../../shared/utils/protocol.utils';
import { UiIconType } from './ui-icon-type';

export function UiIcon({ element }: { element: UiElementIcon }) {
  const className = element.styleId ? `ikon-${element.styleId} ${element.styleId}` : '';
  const size = element.labels.includes(UIElementLabels.SizeMedium) ? '48px' : '24px';
  const color = UiColorToString(element.color);

  return (
    <Box className={className} sx={{ display: 'flex', alignItems: 'center', fontSize: size, color: color ? `${color}.main` : null }}>
      <UiIconType icon={element.icon} />
    </Box>
  );
}

import { UIElementLabels } from '@ikon-web/event-shared';
import { UiCheckbox } from '@ikon-web/ikon-client';
import { Checkbox } from '@mui/material';
import { useIkon } from '../../shared/hooks/use-ikon';

export function UiCheckboxInput({ element, disabled }: { element: UiCheckbox; disabled?: boolean }) {
  const ikon = useIkon();

  const isDisabled = disabled || element.labels?.includes(UIElementLabels.Disabled);
  const className = `ikon-checkbox ${element.styleId ? `ikon-${element.styleId} ${element.styleId}` : ''}`;

  return (
    <Checkbox
      className={className}
      checked={element.selected}
      onChange={(event) => {
        ikon.sendTextAction(element.actionId, JSON.stringify(event.target.checked));
      }}
      disabled={isDisabled}
    />
  );
}

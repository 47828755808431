import { Box, Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { iframeMessages } from '../shared/utils/iframe.utils';

export function Error() {
  useEffect(() => {
    const sendMessage = () => iframeMessages({ type: 'error' });

    sendMessage();

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        sendMessage();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="h1" mt={5}>
        IkonAI
      </Typography>

      <Typography variant="body1" mt={8}>
        Sorry, an unexpected error has occurred.
      </Typography>
      <Button variant="text" component={RouterLink} to="/">
        Go back
      </Button>
    </Box>
  );
}

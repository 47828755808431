import { UIElementLabels } from '@ikon-web/event-shared';
import Stack from '@mui/material/Stack';
import { UiContainerElements } from './ui-container-elements';

export function UiContainerRow({ container, isCard, myMessage, disabled }: { container: any; isCard: boolean; myMessage: boolean; disabled?: boolean }) {
  const className = `ikon-container-row ${container.styleId ? `ikon-${container.styleId} ${container.styleId}` : ''}`;

  return (
    <Stack
      className={className}
      direction="row"
      spacing={container.gap || 0}
      useFlexGap
      sx={{
        width: '100%',
        alignItems: 'center',
        justifyContent: 'left',
        flexWrap: container.labels.includes(UIElementLabels.Wrap) ? 'wrap' : 'nowrap',
      }}
    >
      <UiContainerElements container={container} isCard={isCard} myMessage={myMessage} disabled={disabled} />
    </Stack>
  );
}

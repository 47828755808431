import { useEffect, useState } from 'react';
import { api } from '../../shared/api/api';
import { domain } from '../../shared/utils/domain.utils';

export function useConsumeInvitation(invitationId: string) {
  const [state, setState] = useState<'processing' | 'ok' | 'error'>('processing');
  const [error, setError] = useState<string | undefined>();

  const [getSpace] = api.useLazyGetSpaceByDomainQuery();
  const [getProfile] = api.useLazyGetProfileByMeQuery();
  const [consumeInvitation] = api.useConsumeInvitationMutation();

  useEffect(() => {
    const process = async () => {
      try {
        setState('processing');
        await consumeInvitation(invitationId).unwrap();
        setState('ok');
      } catch (err) {
        try {
          const space = await getSpace(domain).unwrap();
          await getProfile({ space: space?.id }).unwrap();

          console.debug('[Meet] Invitation not found but profile exists, redirecting to space');
          setState('ok');
        } catch {
          setState('error');
          setError(err.data.error);
        }
      }
    };

    process();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationId]);

  return {
    state,
    error,
  };
}

import { UIBadgeVariant, UIElementLabels } from '@ikon-web/event-shared';
import { UiElementBadge } from '@ikon-web/ikon-client';
import { Chip, SxProps } from '@mui/material';
import { useIkon } from '../../shared/hooks/use-ikon';
import { UiColorToString } from '../../shared/utils/protocol.utils';

export function UiBadge({ element, disabled }: { element: UiElementBadge; disabled?: boolean }) {
  const ikon = useIkon();

  const isDisabled = disabled || element.labels?.includes(UIElementLabels.Disabled);
  const variant = element.variant === UIBadgeVariant.Outlined ? 'outlined' : 'filled';

  const sx: SxProps = { height: 'inherit', py: '4px', '& .MuiChip-label': { overflowWrap: 'break-word', whiteSpace: 'normal', textOverflow: 'clip' } };
  const className = element.styleId ? `ikon-${element.styleId} ${element.styleId}` : '';

  if (element.action) {
    return (
      <Chip
        className={className}
        variant={variant}
        color={UiColorToString(element.color)}
        label={element.text}
        disabled={isDisabled}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          ikon.sendAction(element.action);
        }}
        sx={sx}
      />
    );
  }

  return <Chip variant={variant} color={UiColorToString(element.color)} label={element.text} disabled={isDisabled} sx={sx} className={className} />;
}

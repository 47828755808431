import { SpaceFlag } from '@ikon-web/space-types';
import { Box, IconButton } from '@mui/material';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSpace } from '../../shared/hooks/use-space';
import { Disclaimer } from '../disclaimer/disclaimer';
import { DisclaimerPermanent } from '../disclaimer/disclaimer-permanent';
import { ChatContainers } from './chat-containers';
import { ChatContainersScrollLatestTop } from './chat-containers-scroll-latest-top';
import { ChatHeaderContainers } from './chat-header-containers';
import { ChatInput } from './chat-input';
import { selectShowScrollToBottom } from './chat.slice';

export function Chat({
  sendMessage,
  sendFile,
  setMicrophone,
  controlPosition,
  enableInput,
  enablePushToTalk,
  disableInitialScroll,
}: {
  sendMessage: (text: string, isComplete: boolean) => void;
  sendFile: (file: File) => void;
  setMicrophone: (enabled: boolean) => void;
  controlPosition: string;
  enableInput: boolean;
  enablePushToTalk: boolean;
  disableInitialScroll?: boolean;
}) {
  const space = useSpace();
  const chatRef = useRef(null);
  const showScrollToBottom = useSelector(selectShowScrollToBottom);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        height: '100%',
        maxWidth: '100%',
      }}
    >
      <ChatHeaderContainers />

      {space.flags?.includes(SpaceFlag.ChatScrollLatestTop) ? (
        <ChatContainersScrollLatestTop alignEnd={enableInput} centerMessages={!enableInput} hasFade={enableInput} disableInitialScroll={disableInitialScroll} ref={chatRef} />
      ) : (
        <ChatContainers alignEnd={enableInput} centerMessages={!enableInput} hasFade={enableInput} disableInitialScroll={disableInitialScroll} ref={chatRef} />
      )}

      <Box sx={{ position: 'relative', mb: enableInput ? 1 : 0 }}>
        <Disclaimer />
        {showScrollToBottom && (
          <Box
            className="ikon-scroll-to-bottom"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              mx: 2,
            }}
          >
            <IconButton
              size="small"
              sx={(theme) => ({
                borderRadius: '10px',
                border: `1px solid ${theme.palette.border.overlayBorder}`,
                background: theme.palette.surface.background.plane,
                padding: '6px',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  opacity: 0,
                  transition: 'opacity 0.3s',
                  borderRadius: '10px',
                },
                '&:hover': {
                  background: theme.palette.surface.background.plane, //theme.palette.transparent.neutral.hover,
                },
                '&:hover::after': {
                  opacity: 1,
                  backgroundColor: theme.palette.transparent.neutral.hover,
                },
                '&:active': {
                  background: theme.palette.surface.background.plane,
                },
                '&:active::after': {
                  opacity: 1,
                  backgroundColor: theme.palette.transparent.neutral.pressed,
                },
              })}
              onClick={() => {
                if (chatRef) {
                  if (chatRef.current) {
                    (chatRef.current as any).scrollToBottom();
                  }
                }
              }}
            >
              <Box
                component="span"
                className="material-symbols-rounded"
                sx={{
                  fontSize: '18px',
                }}
              >
                arrow_downward
              </Box>
            </IconButton>
          </Box>
        )}
      </Box>

      {enableInput && (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', px: 2, scrollbarGutter: 'stable' }}>
          <ChatInput sendMessage={sendMessage} sendFile={sendFile} setMicrophone={setMicrophone} enablePushToTalk={enablePushToTalk} controlPosition={controlPosition} />
        </Box>
      )}

      <DisclaimerPermanent />
    </Box>
  );
}

import { ContextType, Opcode, PayloadType, ProtocolVersion, UserType } from '@ikon-web/event-shared';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentRoomState, setConnectRoom, setJoiningRoom, setJoinRoom, setRoom, setRoomError } from '../../features/room/room.slice';
import { api } from '../api/api';

export function useConnectRoom(code: string, deviceId: string, embeddedSpaceId?: string, hasInput?: boolean) {
  const dispatch = useDispatch();
  const connectDataRef = useRef<{ code: string }>({ code });

  const currentRoom = useSelector(selectCurrentRoomState);

  const { data: room } = api.useGetRoomByCodeQuery(code, { skip: !code, refetchOnMountOrArgChange: true });

  const [joinRoom] = api.useJoinRoomMutation();
  const [connectRoom] = api.useConnectRoomMutation();

  useEffect(() => {
    if (!room) return;
    let timeout: any;

    connectDataRef.current = { code };

    let count = 0;
    const poll = async () => {
      try {
        if (connectDataRef.current.code !== code) return;

        console.log(`[UseConnectRoom] Room ${code} connecting`);
        const connectData = await connectRoom({
          code,
          contextType: ContextType.Browser,
          userType: UserType.Human,
          payloadType: PayloadType.MessagePack,
          description: 'Ikon Web Client',
          deviceId,
          productId: '',
          versionId: '',
          installId: '',
          locale: navigator.language,
          opcodeGroupsFromServer:
            Opcode.GROUP_CORE | Opcode.GROUP_ANALYTICS | Opcode.GROUP_ACTIONS | Opcode.GROUP_UI | Opcode.GROUP_AUDIO | Opcode.GROUP_VIDEO | Opcode.GROUP_SCENE | Opcode.GROUP_MISC,
          opcodeGroupsToServer: Opcode.GROUP_CORE | Opcode.GROUP_ACTIONS | Opcode.GROUP_AUDIO | Opcode.GROUP_VIDEO | Opcode.GROUP_TRACKING | Opcode.GROUP_MISC,
          protocolVersion: ProtocolVersion,
          hasInput,
          embeddedSpaceId,
          userAgent: navigator.userAgent,
        }).unwrap();

        if (connectDataRef.current.code !== code) return;

        if (connectData.state === 'running') {
          console.log(`[UseConnectRoom] Room ${code} running`);
          dispatch(setConnectRoom(connectData));
        } else if (connectData.state === 'stopped') {
          console.debug(`[UseConnectRoom] Room ${code} stopped`);
          dispatch(setRoomError({ error: 'Room creation failed. Please try again later.' }));
        } else {
          if (++count > 60) {
            dispatch(setRoomError({ error: 'Failed to get connection' }));
            return;
          }
          timeout = setTimeout(() => poll(), 1000);
        }
      } catch {
        dispatch(setRoomError({ error: 'Failed to get connection' }));
      }
    };

    const connect = async () => {
      try {
        dispatch(setRoom({ room }));

        if (currentRoom.isJoining) return;
        if (!currentRoom.isJoined) {
          console.debug(`[UseConnectRoom] Room ${code} joining`);
          dispatch(setJoiningRoom());
          const updatedRoom = await joinRoom({ code, deviceId }).unwrap();
          dispatch(setJoinRoom({ room: updatedRoom }));
        }

        poll();
      } catch (err) {
        console.debug(`[UseConnectRoom] Room ${code} joining failed`, err);
        dispatch(setRoomError({ error: 'Failed to join room' }));
      }
    };

    connect();

    return () => {
      if (timeout) clearTimeout(timeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room]);

  return currentRoom;
}

import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, IconButton } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Fade } from '../../components/fade';
import { useSpace } from '../../shared/hooks/use-space';
import { DisclaimerText } from './disclaimer-text';
import { useDisclaimer } from './use-disclaimer';

export function Disclaimer() {
  const space = useSpace();
  const disclaimer = useDisclaimer();

  if (space.customisation.disclaimerType) return null;

  return (
    <Fade show={disclaimer.isOpen}>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          mx: 2,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '800px',
            zIndex: 1000,
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            useFlexGap
            sx={{
              alignItems: 'center',
              color: 'text.secondary',
              backgroundColor: 'surface.background.overlayTransparent',
              backdropFilter: 'blur(24px)',
              borderRadius: '14px',
              p: 1.5,
            }}
          >
            <InfoOutlinedIcon sx={{ display: { xs: 'none', sm: 'block' } }} />
            <Box sx={{ flex: 1 }}>
              <DisclaimerText space={space} />
            </Box>
            <IconButton onClick={() => disclaimer.close()}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </Box>
      </Box>
    </Fade>
  );
}

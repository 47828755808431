import { forwardRef, ReactNode, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react';
import './qsapp.css';

interface QSAppProps {
  id: string;
  loadingAnimation?: ReactNode;
  className?: string;
  onStart?: () => void;
  actions?: Record<string, (action: string) => void>;
  deferLoading?: boolean;
  hideLoadingAnimation?: boolean;
  mode?: 'canvas' | 'iframe';
  styles: any;
}

export interface QSRef {
  send: (event: string) => void;
  setLayoutText: (layout: string, element: string, text: string) => void;
}

declare global {
  interface Window {
    qsapp: any;
  }
}

const EVENT_ORIGIN = 'https://qs.app';
const CANVAS_SCRIPT = 'https://qs.app/bundle.canvas.web.production.03491cc81d66ae4c94b4.js';

const DefaultSpinner = () => (
  <div className="spinner-container">
    <div className="spinner" role="status" aria-label="Loading" />
  </div>
);

const QSApp = forwardRef((props: QSAppProps, ref: Ref<QSRef>) => {
  const [url, setUrl] = useState<string | undefined>(undefined);
  const [showLoading, setShowLoading] = useState(true);
  const [deferLoading, setDeferLoading] = useState(props.deferLoading || false);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const canvasRef = useRef<HTMLDivElement | null>(null);
  const [loadingAnimation, setLoadingAnimation] = useState(props.loadingAnimation || <DefaultSpinner />);
  const [mode, setMode] = useState(props.mode ?? 'canvas');
  const [api, setApi] = useState<any>();

  useEffect(() => {
    setShowLoading(true);
    setUrl(`https://qs.app/?id=${props.id}`);
  }, [iframeRef, props.id]);

  useEffect(() => {
    window.addEventListener('message', receive, false);
    return () => window.removeEventListener('message', receive);
  }, []);

  useEffect(() => {
    if (mode === 'canvas') {
      const script = document.createElement('script');

      script.src = CANVAS_SCRIPT;
      script.async = true;

      document.body.appendChild(script);

      script.onload = async () => {
        setApi(await window.qsapp.create(canvasRef.current, props.id, { onMessage: (message: MessageEvent) => receive(message) }));
      };

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [props.id]);

  useEffect(() => {
    if (deferLoading && props.deferLoading === false) {
      setDeferLoading(props.deferLoading);
    }
  }, [props.deferLoading]);

  function receive(event: MessageEvent) {
    if (mode === 'iframe') {
      if (!event.source || event.origin !== EVENT_ORIGIN || event.source !== iframeRef.current?.contentWindow) {
        return;
      }
    }

    const data = mode === 'iframe' ? event.data : event;
    switch (data.type) {
      case 'start': {
        setShowLoading(false);
        if (props.onStart) {
          props.onStart();
        }
        break;
      }

      case 'action': {
        if (props.actions && props.actions[data.name]) {
          props.actions[data.name](data.name);
        }
        break;
      }

      default:
        break;
    }
  }

  const send = (event: string) => {
    const postMessage = { type: 'interpreter-send', event };

    if (mode === 'iframe') {
      if (iframeRef.current && iframeRef.current.contentWindow) {
        iframeRef.current.contentWindow.postMessage(postMessage, EVENT_ORIGIN);
      }
    }

    if (mode === 'canvas') {
      api.postMessage(postMessage);
    }
  };

  const setLayoutText = (layout: string, element: string, text: string) => {
    const postMessage = { type: 'set-text', layout, element, text };

    if (mode === 'iframe') {
      if (iframeRef.current && iframeRef.current.contentWindow) {
        iframeRef.current.contentWindow.postMessage(postMessage, EVENT_ORIGIN);
      }
    }

    if (mode === 'canvas') {
      api.postMessage(postMessage);
    }
  };

  useImperativeHandle(ref, () => ({ send, setLayoutText }));

  return (
    <div className={`qsapp_container ${props.className ? props.className : ''}`} style={props.styles ?? {}}>
      {!props.hideLoadingAnimation && (showLoading || deferLoading) && (
        <div role="status" className="qsapp_loadingContainer">
          {loadingAnimation}
          <span className="sr-only">Loading...</span>
        </div>
      )}
      {mode === 'canvas' ? <div id="qsapp-canvas" className="qsapp_content" ref={canvasRef}></div> : <iframe src={url} ref={iframeRef} allow="autoplay" className="qsapp_content" />}
    </div>
  );
});

export default QSApp;

import { SpaceFont } from '@ikon-web/space-types';
import { createTheme, CssBaseline, PaletteMode, Theme, ThemeOptions, ThemeProvider } from '@mui/material';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { color } from '../color';
import { LOCAL_STORAGE_THEME, LOCAL_STORAGE_THEME_USER } from '../constants';
import { selectEmbeddedSpaceTheme } from '../data/embedded-space.slice';
import { useSpace } from '../hooks/use-space';
import { ThemeSelectorContext } from './theme-selector.context';

declare module '@mui/material/styles' {
  interface Palette {
    onSurface: {
      highEmphasis: string;
      mediumEmphasis: string;
      lowEmphasis: string;
      disabled: string;
    };
    onPrimary: {
      highEmphasis: string;
      mediumEmphasis: string;
      lowEmphasis: string;
      disabled: string;
    };
    transparent: {
      neutral: { hover: string; focus: string; pressed: string; selected: string };
      primary: { hover: string; focus: string; pressed: string; selected: string };
    };
    surface: {
      background: {
        plane: string;
        overlaySolid: string;
        overlayTransparent: string;
        speechBubbleOther: string;
        speechBubbleMy: string;
        input: string;
        disabled: string;
      };
    };
    border: {
      overlayBorder: string;
      overlayDivider: string;
      divider: string;
      cardBorder: string;
    };
    visited: { main: string };
    neutral: { main: string };
  }

  interface PaletteOptions {
    onSurface: {
      highEmphasis: string;
      mediumEmphasis: string;
      lowEmphasis: string;
      disabled: string;
    };
    onPrimary: {
      highEmphasis: string;
      mediumEmphasis: string;
      lowEmphasis: string;
      disabled: string;
    };
    transparent: {
      neutral: { hover: string; focus: string; pressed: string; selected: string };
      primary: { hover: string; focus: string; pressed: string; selected: string };
    };
    surface: {
      background: {
        plane: string;
        overlaySolid: string;
        overlayTransparent: string;
        speechBubbleOther: string;
        speechBubbleMy: string;
        input: string;
        disabled: string;
      };
    };
    border: {
      overlayBorder: string;
      overlayDivider: string;
      divider: string;
      cardBorder: string;
    };
    visited: { main: string };
    neutral: { main: string };
  }
}

const buildTheme = (theme: Theme) =>
  createTheme(theme, {
    components: {
      MuiChip: {
        styleOverrides: {
          root: {
            // borderRadius: '12px',
            // fontSize: '14px',
            // fontWeight: 400,
            // lineHeight: '18xp',

            '&.MuiChip-outlined': {
              // border: `1.2px solid ${theme.palette.border.cardBorder}`,
              // backgroundColor: theme.palette.surface.background.speechBubbleOther,
              // backdropFilter: 'blur(24px)',

              '&.MuiChip-clickable:hover': {
                // backgroundColor: theme.palette.transparent.neutral.hover,
              },
            },

            '&.MuiChip-filled': {
              // backdropFilter: 'blur(24px)',

              '&.MuiChip-colorDefault': {
                // backgroundColor: theme.palette.onSurface.highEmphasis,
                // color: theme.palette.onPrimary.highEmphasis,
              },
            },
          },
        },
      },
      MuiIconButton: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          sizeMedium: {
            borderRadius: '12px',
          },
          sizeSmall: {
            borderRadius: '8px',
          },
          root: {
            '&:active': {
              backgroundColor: theme.palette.transparent.neutral.selected,
            },
            '&:hover': {
              backgroundColor: theme.palette.transparent.neutral.hover,
            },
          },
        },
      },
      MuiButtonBase: {
        defaultProps: { disableRipple: true },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              color: theme.palette.text.secondary,
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundColor: theme.palette.background.default,
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: theme.palette.surface.background.overlayTransparent,
            backgroundImage: 'none',
            border: '1px solid',
            borderColor: theme.palette.border.overlayBorder,
            borderRadius: '16px',
            boxShadow: '0px 24px 48px -12px rgba(0, 0, 0, 0.15)',
            backdropFilter: 'blur(32px)',
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          paper: {
            backgroundColor: theme.palette.surface.background.overlaySolid,
          },
        },
      },
    },
  });

const lightPalette: ThemeOptions = {
  palette: {
    mode: 'light',
    onSurface: {
      highEmphasis: color.transparent.black[90],
      mediumEmphasis: color.transparent.black[60],
      lowEmphasis: color.transparent.black[40],
      disabled: color.transparent.black[25],
    },
    onPrimary: {
      highEmphasis: color.transparent.white[90],
      mediumEmphasis: color.transparent.white[60],
      lowEmphasis: color.transparent.white[40],
      disabled: color.transparent.white[25],
    },
    transparent: {
      neutral: {
        hover: color.transparent.black[8],
        focus: color.transparent.black[12],
        pressed: color.transparent.black[10],
        selected: color.transparent.black[10],
      },
      primary: {
        hover: color.transparent.primary600[8],
        focus: color.transparent.primary600[12],
        pressed: color.transparent.primary600[10],
        selected: color.transparent.primary600[10],
      },
    },
    surface: {
      background: {
        plane: color.neutral[100],
        overlaySolid: color.neutral[50],
        overlayTransparent: color.transparent.white[40],
        speechBubbleOther: color.transparent.white[30],
        speechBubbleMy: color.transparent.black[10],
        input: color.transparent.white[60],
        disabled: color.transparent.black[25],
      },
    },
    border: {
      overlayBorder: color.neutral[200],
      overlayDivider: color.neutral[300],
      divider: color.transparent.black[60],
      cardBorder: color.transparent.black[40],
    },
    primary: {
      main: color.primaryViolet[600],
    },
    visited: {
      main: color.purple[700],
    },
    success: {
      main: color.green[600],
    },
    error: {
      main: color.red[600],
    },
    info: {
      main: color.blue[600],
    },
    warning: {
      main: color.orange[600],
    },
    neutral: {
      main: color.neutral[600],
    },

    // For MUI

    background: {
      default: color.neutral[100],
      paper: color.transparent.white[40],
    },
    text: {
      primary: color.transparent.black[90],
      secondary: color.transparent.black[60],
      disabled: color.transparent.black[25],
    },
  },
  typography: {
    fontFamily: 'Noto Sans, sans-serif',
  },
};

const darkPalette: ThemeOptions = {
  palette: {
    mode: 'dark',
    onSurface: {
      highEmphasis: color.transparent.white[90],
      mediumEmphasis: color.transparent.white[60],
      lowEmphasis: color.transparent.white[40],
      disabled: color.transparent.white[25],
    },
    onPrimary: {
      highEmphasis: color.transparent.black[90],
      mediumEmphasis: color.transparent.black[60],
      lowEmphasis: color.transparent.black[40],
      disabled: color.transparent.black[25],
    },
    transparent: {
      neutral: {
        hover: color.transparent.white[8],
        focus: color.transparent.white[12],
        pressed: color.transparent.white[10],
        selected: color.transparent.white[10],
      },
      primary: {
        hover: color.transparent.primary300[8],
        focus: color.transparent.primary300[12],
        pressed: color.transparent.primary300[10],
        selected: color.transparent.primary300[10],
      },
    },
    surface: {
      background: {
        plane: color.neutral[900],
        overlaySolid: color.neutral[950],
        overlayTransparent: color.transparent.black[40],
        speechBubbleOther: color.transparent.black[20],
        speechBubbleMy: color.transparent.white[10],
        input: color.transparent.black[60],
        disabled: color.transparent.white[25],
      },
    },
    border: {
      overlayBorder: color.neutral[700],
      overlayDivider: color.neutral[700],
      divider: color.transparent.white[60],
      cardBorder: color.transparent.white[40],
    },
    primary: {
      main: color.primaryViolet[300],
    },
    visited: {
      main: color.purple[400],
    },
    success: {
      main: color.green[200],
    },
    error: {
      main: color.red[400],
    },
    info: {
      main: color.blue[400],
    },
    warning: {
      main: color.orange[400],
    },
    neutral: {
      main: color.neutral[300],
    },

    // For MUI

    background: {
      default: color.neutral[900],
      paper: color.transparent.black[40],
    },
    text: {
      primary: color.transparent.white[90],
      secondary: color.transparent.white[60],
      disabled: color.transparent.white[25],
    },
  },
  typography: {
    fontFamily: 'Noto Sans, sans-serif',
  },
};

const availableThemes = [
  { code: 'dark', name: 'Dark' },
  { code: 'light', name: 'Light' },
];

export function ThemeSelectorProvider(opts: { children?: ReactNode }) {
  const [mode, setMode] = useState<PaletteMode>((localStorage.getItem(LOCAL_STORAGE_THEME) as PaletteMode) || 'dark');
  const space = useSpace();
  const embeddedSpaceTheme = useSelector(selectEmbeddedSpaceTheme);
  const [font, setFont] = useState('');

  useEffect(() => {
    if (!space?.customisation?.css) return;

    let styleTag = document.getElementById('ikon-sds') as HTMLStyleElement;

    if (!styleTag) {
      styleTag = document.createElement('style');
      styleTag.id = 'ikon-sds';
      document.head.appendChild(styleTag);
    }

    styleTag.innerHTML = space.customisation.css;
  }, [space]);

  function selectTheme(newMode: PaletteMode) {
    localStorage.setItem(LOCAL_STORAGE_THEME, newMode);
    setMode(newMode);
  }

  const selectUserTheme = useCallback((newMode: PaletteMode) => {
    localStorage.setItem(LOCAL_STORAGE_THEME_USER, newMode);
    localStorage.setItem(LOCAL_STORAGE_THEME, newMode);
    setMode(newMode);
  }, []);

  useEffect(() => {
    setFont(space?.font || '');
    const userTheme = localStorage.getItem(LOCAL_STORAGE_THEME_USER) as PaletteMode;
    if (userTheme) {
      if (userTheme !== mode) {
        selectTheme(userTheme);
      }
      return;
    }

    if (embeddedSpaceTheme) {
      if (embeddedSpaceTheme !== mode) {
        selectTheme(embeddedSpaceTheme as PaletteMode);
      }
      return;
    }

    if (space && space.theme && space.theme !== mode) {
      selectTheme(space.theme);
    }
  }, [mode, space, embeddedSpaceTheme]);

  const theme = useMemo(() => {
    const palette = mode === 'dark' ? darkPalette : lightPalette;
    if (font === SpaceFont.Poppins) {
      palette.typography = { fontFamily: 'Poppins, sans-serif' };
    } else if (font) {
      palette.typography = { fontFamily: font };
    }

    return buildTheme(
      createTheme(palette, {
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                textTransform: 'none',
                fontWeight: 400,
              },
              sizeSmall: {
                padding: '4px 14px',
                borderRadius: '10px',
              },
              sizeMedium: {
                borderRadius: '12px',
              },
              sizeLarge: {
                padding: '10px 16px',
                borderRadius: '14px',
                fontSize: '16px',
              },
            },
          },
          MuiIconButton: {
            styleOverrides: {
              sizeSmall: {
                padding: '7px',
                borderRadius: '10px',
              },
              sizeMedium: {
                padding: '8px',
                borderRadius: '12px',
              },
              sizeLarge: {
                padding: '12px',
                borderRadius: '14px',
              },
            },
          },
        },
      }),
    );
  }, [mode, font]);

  return (
    <ThemeSelectorContext.Provider value={{ theme: mode, selectTheme: selectUserTheme, availableThemes }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {opts.children}
      </ThemeProvider>
    </ThemeSelectorContext.Provider>
  );
}

import { UIElementLabels } from '@ikon-web/event-shared';
import { UiElementHeader } from '@ikon-web/ikon-client';
import { Box, SxProps, Typography } from '@mui/material';
import { useMemo } from 'react';

export function UiHeader({ element }: { element: UiElementHeader }) {
  const className = `ikon-header ${element.styleId ? `ikon-${element.styleId} ${element.styleId}` : ''}`;
  const sxProps = useMemo(() => {
    const props: SxProps = {};
    if (element.labels.includes(UIElementLabels.Blur)) {
      props['filter'] = 'blur(8px)';
    }

    return props;
  }, [element.labels]);

  if (!element.text) return null;

  if (element.level === 'large') {
    return (
      <Box className={className}>
        <Typography component="h1" variant="h4" sx={{ mb: 0, ...sxProps }}>
          {element.text}
        </Typography>
        {element.subText && (
          <Typography component="span" variant="caption" sx={{ color: '#8B8C92', ...sxProps }}>
            {element.subText}
          </Typography>
        )}
      </Box>
    );
  }

  if (element.level === 'medium') {
    return (
      <Box className={className}>
        <Typography component="h2" variant="h6" sx={{ mb: 0, fontSize: '20px', ...sxProps }}>
          {element.text}
        </Typography>
        {element.subText && (
          <Typography component="span" variant="caption" sx={{ color: '#8B8C92', ...sxProps }}>
            {element.subText}
          </Typography>
        )}
      </Box>
    );
  }

  if (element.level === 'small') {
    return (
      <Box className={className}>
        <Typography component="h4" variant="h6" sx={{ fontSize: '14px', fontWeight: 500, mb: 0, ...sxProps }}>
          {element.text}
        </Typography>
        {element.subText && (
          <Typography component="span" variant="caption" sx={{ color: '#8B8C92', ...sxProps }}>
            {element.subText}
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <Box className={className}>
      <Typography component="h3" variant="h6" sx={{ fontSize: '16px', fontWeight: 500, mb: 0, ...sxProps }}>
        {element.text}
      </Typography>
      {element.subText && (
        <Typography component="span" variant="caption" sx={{ color: '#8B8C92', ...sxProps }}>
          {element.subText}
        </Typography>
      )}
    </Box>
  );
}

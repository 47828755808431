import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LOCAL_STORAGE_DISCLAIMER_SHOWN } from '../../shared/constants';
import { closeDisclaimer, openDisclaimer, selectDisclaimerOpen } from './disclaimer.slice';

export function useDisclaimer() {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectDisclaimerOpen);

  useEffect(() => {
    const disclaimerShown = localStorage.getItem(LOCAL_STORAGE_DISCLAIMER_SHOWN);
    if (disclaimerShown) {
      dispatch(closeDisclaimer());
    } else {
      dispatch(openDisclaimer());
    }
  }, [dispatch]);

  const close = useCallback(() => {
    if (isOpen) {
      localStorage.setItem(LOCAL_STORAGE_DISCLAIMER_SHOWN, new Date().valueOf().toString());
      dispatch(closeDisclaimer());
    }
  }, [dispatch, isOpen]);

  return {
    isOpen,
    close,
  };
}

import { UIElementLabels } from '@ikon-web/event-shared';
import { UiElementText } from '@ikon-web/ikon-client';
import { Box, SxProps, Typography } from '@mui/material';
import { useMemo } from 'react';
import { UiColorToString } from '../../shared/utils/protocol.utils';
import { UiTextMarkdown } from './ui-text-markdown';

export function UiText({ element, myMessage, disabled }: { element: UiElementText; myMessage: boolean; disabled?: boolean }) {
  const className = `${element.styleId ? ` ikon-${element.styleId} ${element.styleId}` : ''}`;

  const sxProps = useMemo(() => {
    const props: SxProps = {};
    if (element.labels.includes(UIElementLabels.Blur)) {
      props['filter'] = 'blur(8px)';
    }

    return props;
  }, [element.labels]);

  const color = useMemo(() => {
    if (disabled) return 'text.disabled';

    const color = UiColorToString(element.color);

    return `${color}.main`;
  }, [disabled, element.color]);

  if (!element.text) return null;

  if (element.labels.includes(UIElementLabels.ChatMessage)) {
    return (
      <Box
        className={`ikon-chat-message-${myMessage ? 'my' : 'other'} ${className}`}
        sx={{
          padding: '12px',
          borderRadius: '14px',
          backgroundColor: myMessage ? 'surface.background.speechBubbleMy' : 'surface.background.speechBubbleOther',
          backdropFilter: 'blur(80px)',
          color: 'text.primary',
          lineHeight: '24px',
        }}
      >
        <UiTextMarkdown text={element.text} sx={sxProps} />
      </Box>
    );
  }

  if (element.labels.includes(UIElementLabels.Markdown)) {
    return (
      <Box className={className} sx={{ color: 'text.primary', lineHeight: '24px' }}>
        <UiTextMarkdown text={element.text} sx={sxProps} />
      </Box>
    );
  }

  if (element.type === 'strong')
    return (
      <Typography
        className={className}
        component="span"
        variant="body1"
        sx={{
          display: 'block',
          fontWeight: 500,
          color: color,
          whiteSpace: 'pre-wrap',
          ...sxProps,
        }}
      >
        {element.text}
      </Typography>
    );

  if (element.type === 'caption')
    return (
      <Typography
        className={className}
        component="span"
        variant="caption"
        sx={{
          display: 'block',
          color: color,
          whiteSpace: 'pre-wrap',
          ...sxProps,
        }}
      >
        {element.text}
      </Typography>
    );

  return (
    <Typography className={className} component="span" variant="body1" sx={{ display: 'block', color: color, whiteSpace: 'pre-wrap', ...sxProps }}>
      {element.text}
    </Typography>
  );
}

import { SpaceDisclaimerType } from '@ikon-web/space-types';
import { Box } from '@mui/material';
import { useSpace } from '../../shared/hooks/use-space';
import { DisclaimerText } from './disclaimer-text';

export function DisclaimerPermanent() {
  const space = useSpace();

  if (space.customisation.disclaimerType !== SpaceDisclaimerType.Permanent) return;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1, px: 2 }}>
      <DisclaimerText space={space} />
    </Box>
  );
}

import { UIColor, UIElementLabels, UIMaterialSymbolVariant } from '@ikon-web/event-shared';
import { UiElementMaterialSymbol } from '@ikon-web/ikon-client';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { UiColorToString } from '../../shared/utils/protocol.utils';

export function UiMaterialSymbol({ element, disabled }: { element: UiElementMaterialSymbol; disabled?: boolean }) {
  const className = `material-symbols-rounded${element.styleId ? ` ikon-${element.styleId} ${element.styleId}` : ''}`;
  const size = element.labels.includes(UIElementLabels.SizeSmall) ? '18px' : '24px';

  const color = useMemo(() => {
    if (disabled) return 'text.disabled';

    if (element.color === UIColor.Secondary) return 'text.secondary';
    const color = UiColorToString(element.color);
    if (!color) return null;

    return `${color}.main`;
  }, [element.color, disabled]);

  return (
    <Box component="span" className={className} sx={{ fontSize: size, color, fontVariationSettings: "'FILL' " + (element.variant === UIMaterialSymbolVariant.Filled ? '1' : '0') }}>
      {element.name}
    </Box>
  );
}

import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../shared/data/store';

const initialState: { open: boolean } = { open: false };

const disclaimerSlice = createSlice({
  name: 'disclaimer',
  initialState,
  reducers: {
    open: (state) => {
      state.open = true;
    },
    close: (state) => {
      state.open = false;
    },
    reset: () => initialState,
  },
});

export const disclaimerReducer = disclaimerSlice.reducer;

export const { open: openDisclaimer, close: closeDisclaimer, reset: resetDisclaimer } = disclaimerSlice.actions;

export const selectDisclaimerOpen = (state: RootState) => state.disclaimer.open;

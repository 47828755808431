import { Box, Link, Typography } from '@mui/material';
import { Ikon } from '../../components/ikon';

export function MaintenancePage() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'center', color: 'rgba(0, 0, 0, 0.9)', textAlign: 'center', mt: 4 }}>
      <Box>
        <Ikon />
      </Box>
      <Typography variant="body1">We are bringing updates to improve your experience. Check back soon!</Typography>
      <Typography variant="body1">
        <Link href="mailto:support@ikon.live" sx={{ textDecoration: 'none' }}>
          support@ikon.live
        </Link>
      </Typography>
    </Box>
  );
}

import { UIButtonVariant, UIElementLabels } from '@ikon-web/event-shared';
import { UiElementButton } from '@ikon-web/ikon-client';
import { Button } from '@mui/material';
import { MouseEvent, useMemo } from 'react';
import { useIkon } from '../../shared/hooks/use-ikon';
import { UiColorToString } from '../../shared/utils/protocol.utils';

export function UiButton({ element, disabled }: { element: UiElementButton; disabled?: boolean }) {
  const ikon = useIkon();

  const isDisabled = disabled || element.labels?.includes(UIElementLabels.Disabled);
  const className = `ikon-button ${element.styleId ? `ikon-${element.styleId} ${element.styleId}` : ''}`;

  const variant = useMemo(() => {
    switch (element.variant) {
      case UIButtonVariant.Text:
        return 'text';
      case UIButtonVariant.Contained:
        return 'contained';
      default:
        return 'outlined';
    }
  }, [element.variant]);

  const size = useMemo(() => {
    if (element.labels.includes(UIElementLabels.SizeLarge)) return 'large';
    if (element.labels.includes(UIElementLabels.SizeSmall)) return 'small';
    return 'medium';
  }, [element.labels]);

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    ikon.sendAction(element.action);
  }

  return (
    <Button className={className} variant={variant} size={size} color={UiColorToString(element.color)} disabled={isDisabled} onMouseDown={handleClick}>
      {element.text}
    </Button>
  );
}

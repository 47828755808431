import { UiElementIconButton } from '@ikon-web/ikon-client';
import { IconButton } from '@mui/material';
import { MouseEvent } from 'react';
import { useIkon } from '../../shared/hooks/use-ikon';
import { UiIconType } from './ui-icon-type';

export function UiIconButton({ element, disabled }: { element: UiElementIconButton; disabled?: boolean }) {
  const ikon = useIkon();

  const className = element.styleId;

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    ikon.sendAction(element.action);
  }

  return (
    <IconButton className={className} size="small" disabled={disabled} onClick={handleClick}>
      <UiIconType icon={element.icon} />
    </IconButton>
  );
}

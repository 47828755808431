import { Box, Button, Divider, Link, TextField, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import runsOnIkonMind from '../../assets/runs-on-ikon-mind.svg';
import { useSpace } from '../../shared/hooks/use-space';
import { isIframe } from '../../shared/utils/iframe.utils';
import { useEmailLogin } from './use-email-login';

export function EmailVerifyPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const email = location.state?.email;

  const space = useSpace();

  const emailLogin = useEmailLogin();
  const [emailLoginRequested, setEmailLoginRequested] = useState(false);

  const formik = useFormik({
    initialValues: { code: '' },
    onSubmit: async ({ code }) => {
      try {
        await emailLogin.sendEmailLoginCode(code);
        navigate('/');
      } catch (err) {
        console.log('[Meet] Failed to login with email code', err);
      }
    },
  });

  return (
    <Stack direction="column" useFlexGap gap="64px" sx={{ maxWidth: '320px', alignItems: 'flex-start', height: '100%' }}>
      <Box component="img" src={runsOnIkonMind} sx={{ height: '14px', display: { xs: 'none', md: 'block' } }} />
      <Stack direction="column" useFlexGap gap="48px" sx={{ alignItems: 'flex-start', height: '100%' }}>
        <Typography variant="h1" sx={{ fontSize: '24px', fontWeight: 400, letterSpacing: '0.18px', m: 0, p: 0 }}>
          Log in to {space?.customisation?.name ?? 'Ikon'}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flex: '1 0 0',
            alignSelf: 'stretch',
          }}
        >
          <Stack direction="column" spacing={3} useFlexGap sx={{ flexGrow: 1 }}>
            <Typography variant="body1" sx={{ fontSize: '16px' }}>
              {isIframe ? 'A one-time code has been sent to your email address.' : 'A one-time login link has been sent to your email address. Click the link in the email to log in.'}
            </Typography>

            {isIframe && (
              <Stack direction="column" spacing={2} useFlexGap component="form" onSubmit={formik.handleSubmit} autoComplete="off">
                <TextField
                  fullWidth
                  name="code"
                  label="Code"
                  value={formik.values.code}
                  onChange={formik.handleChange}
                  error={formik.touched.code && Boolean(formik.errors.code)}
                  helperText={formik.touched.code && formik.errors.code}
                />

                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  sx={{
                    color: '#000',
                    background: 'linear-gradient(319deg, #49D6EA 17.18%, #83C3FC 40.79%, #9196FF 74.77%, #9196FF 74.77%)',
                    borderRadius: '14px',
                  }}
                >
                  Login
                </Button>
              </Stack>
            )}

            {!!email && !emailLoginRequested && (
              <Typography variant="body2" sx={{ fontSize: '12px', fontWeight: '400', letterSpacing: '0.25px' }}>
                Didn’t receive the link?{' '}
                <Button
                  variant="text"
                  sx={{ textTransform: 'none' }}
                  disabled={emailLogin.requestLoading}
                  onClick={async () => {
                    await emailLogin.request(email);
                    setEmailLoginRequested(true);
                  }}
                >
                  Resend login link
                </Button>
              </Typography>
            )}

            {emailLoginRequested && (
              <Typography variant="body2" sx={{ fontSize: '12px', fontWeight: '400', letterSpacing: '0.25px' }}>
                Login email has been resent to {email}
              </Typography>
            )}

            <Divider />

            <Button
              component={RouterLink}
              fullWidth
              to="/auth/login"
              variant="contained"
              disableElevation
              sx={{
                color: '#000',
                background: 'linear-gradient(319deg, #49D6EA 17.18%, #83C3FC 40.79%, #9196FF 74.77%, #9196FF 74.77%)',
                borderRadius: '14px',
              }}
            >
              Go back
            </Button>
          </Stack>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center', alignSelf: 'stretch' }}>
            <Link href="mailto:support@ikon.live" sx={{ fontSize: '12px', fontWeight: '300', textDecoration: 'none', color: 'text.primary' }}>
              support@ikon.live
            </Link>
          </Box>
        </Box>
      </Stack>

      <Box sx={{ justifyContent: 'center', alignSelf: 'stretch', display: { xs: 'flex', md: 'none' } }}>
        <Box component="img" src={runsOnIkonMind} sx={{ height: '14px' }} />
      </Box>
    </Stack>
  );
}

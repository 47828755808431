import { UIElementLabels } from '@ikon-web/event-shared';
import { UiElementImage } from '@ikon-web/ikon-client';
import * as MUIcon from '@mui/icons-material';
import { Avatar, Box, SxProps, Typography } from '@mui/material';
import { MouseEvent } from 'react';
import { color } from '../../shared/color';
import { useIkon } from '../../shared/hooks/use-ikon';

export function UiImage({ element, isCard }: { element: UiElementImage; isCard: boolean }) {
  const ikon = useIkon();

  const className = `ikon-image${element.styleId ? ` ikon-${element.styleId} ${element.styleId}` : ''}`;

  function handleClick(event: MouseEvent<HTMLDivElement>) {
    if (element.actionId) {
      event.preventDefault();
      ikon.sendAction(element.actionId);
    }
  }

  if (isCard) {
    if (element.labels?.includes(UIElementLabels.ImageAvatar)) {
      return (
        <Avatar className={className} src={element.url} alt={element.name} variant="circular" sx={{ width: '20px', height: '20px' }}>
          <MUIcon.Image />
        </Avatar>
      );
    }

    const sx: SxProps = { background: '#fff', padding: '8px', opacity: '0.8', objectFit: element.labels?.includes(UIElementLabels.SizeFitContent) ? 'contain' : 'cover' };
    if (element.labels?.includes(UIElementLabels.SizeLarge)) {
      sx.width = '100px';
      sx.height = '100px';
    }

    if (element.labels?.includes(UIElementLabels.SizeMedium)) {
      sx.width = '64px';
      sx.height = '64px';
    }

    if (element.labels?.includes(UIElementLabels.SizeSmall)) {
      sx.width = '40px';
      sx.height = '40px';
    }

    if (element.labels?.includes(UIElementLabels.SizeExtraSmall)) {
      sx.width = '20px';
      sx.height = '20px';
      sx.padding = '4px';
    }

    if (element.labels?.includes('wide')) {
      sx.width = '80px';
      sx.height = '40px';
    }

    return (
      <Box>
        <Box className={className} component="img" src={element.url} alt={element.name} sx={sx}></Box>
      </Box>
    );
  }

  const size = element.labels?.includes(UIElementLabels.SizeLarge) ? '84px' : element.labels?.includes(UIElementLabels.SizeMedium) ? '48px' : '100%';

  const objectFit = element.labels?.includes(UIElementLabels.SizeFitContent) ? 'contain' : 'cover';

  return (
    <Box
      className={className}
      onClick={(event) => handleClick(event)}
      sx={{
        height: size,
        width: size,
        position: 'relative',
        cursor: element.actionId ? 'pointer' : null,
        '&:hover img': element.actionId
          ? {
              opacity: 0.8,
            }
          : null,
      }}
    >
      <Box
        sx={{
          display: 'inline-block',
          height: '100%',
          width: '100%',
        }}
      >
        <Box
          component="img"
          src={element.url}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: objectFit,
            display: 'block',
            borderRadius: '8px',
            transition: 'all 0.3s ease-in-out',
          }}
        />
      </Box>
      {element.name && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            width: '100%',
            p: 1,
            zIndex: 1000,
            background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.00) 50%, rgba(0, 0, 0, 0.60) 100%)',
            borderRadius: '0 0 8px 8px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'flex-end', height: '100%' }}>
            <Typography
              variant="subtitle1"
              sx={{
                marginTop: 'auto',
                textAlign: 'left',
                fontWeight: 500,
                lineHeight: '18px',
                color: color.transparent.white[90],
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '3',
                lineClamp: '3',
                WebkitBoxOrient: 'vertical',
              }}
              title={element.name}
            >
              {element.name}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}

import { useCallback } from 'react';

export function useCustomStyles() {
  const setStyle = useCallback((css: string) => {
    let styleTag = document.getElementById('ikon-sds-room');
    if (!styleTag) {
      styleTag = document.createElement('style');
      styleTag.id = `ikon-sds-room`;
      document.head.appendChild(styleTag);
    }

    styleTag.innerHTML += css;
  }, []);

  const resetStyle = useCallback(() => {
    const styleTag = document.getElementById('ikon-sds-room');
    styleTag?.remove();
  }, []);

  return { setStyle, reset: resetStyle };
}

import { UiContainerModel } from '@ikon-web/ikon-client';
import { Box, Fade } from '@mui/material';
import { MutableRefObject, useEffect, useState } from 'react';
import { STAGGER_DELAY } from '../../shared/constants';
import { useIkon } from '../../shared/hooks/use-ikon';
import { UiContainerElements } from './ui-container-elements';

export function UiContainerCard({ container, myMessage, disabled, stagger }: { container: UiContainerModel; myMessage: boolean; disabled?: boolean; stagger?: MutableRefObject<number> }) {
  const ikon = useIkon();

  const [visible, setVisible] = useState(false);

  const className = `ikon-container-card ${container.styleId ? `ikon-${container.styleId} ${container.styleId}` : ''}`;

  useEffect(() => {
    let timeout: string | number | NodeJS.Timeout;
    if (stagger) {
      const currentStagger = stagger.current;
      stagger.current += STAGGER_DELAY;
      timeout = setTimeout(() => setVisible(true), currentStagger);
    } else setVisible(true);

    return () => {
      if (timeout) clearTimeout(timeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fade appear={true} in={visible} timeout={500}>
      <div>
        <Box
          className={className}
          onClick={() => ikon.sendAction(container.actionId)}
          sx={(theme) => ({
            width: container.labels?.includes('size-fit-content') ? 'fit-content' : '100%',
            padding: '12px',
            borderRadius: '14px',
            backgroundColor: theme.palette.surface.background.speechBubbleOther,
            backdropFilter: 'blur(48px)',
            '&:hover': container.actionId
              ? {
                  backgroundColor: disabled ? null : 'rgba(0, 0, 0, 0.10)',
                }
              : null,
            transition: 'background-color 0.3s ease',
            cursor: container.actionId && !disabled ? 'pointer' : 'default',
          })}
        >
          <UiContainerElements container={container} isCard={true} myMessage={myMessage} disabled={disabled} />
        </Box>
      </div>
    </Fade>
  );
}
